@import '../../../styles/customMediaQueries.css';

:global(.image-gallery-thumbnails-container) {
  /*
   By default, the gallery controls the scroll position of the thumbnails when
   browsing the images. We change this logic to a freely scrollable container
   that the user controls. This overflow works together with the
   `disableThumbnailScroll` option in the component JS.
   */
  overflow: auto;
  text-align: left !important;
}

:global(.image-gallery-thumbnails) {
  padding-right: 24px;
  padding-bottom: 0;
  padding-top: 0;
}

:global(.image-gallery-thumbnail) {
  width: auto;
  border-radius: 8px;

  text-align: left !important;

  &:first-child {
    margin-left: 0px;

    @media (--viewportMedium) {
      margin-left: 0;
    }
  }

  &:last-child {
    margin-right: 24px;

    @media (--viewportMedium) {
      margin-right: 0;
    }
  }

  &:hover,
  &:global(.active) {
    border-color: var(--marketplaceColor);
  }
}

:global(.image-gallery-slide-wrapper) {
  background-color: var(--matterColorLight);
  border-bottom: 1px solid var(--matterColorNegative);
  overflow: hidden;

  @media (--viewportMedium) {
    border-radius: 10px;
    border: 1px solid var(--matterColorNegative);
  }
}

:global(.fullscreen) {
  background-color: var(--matterColorLight) !important;

  & :global(.image-gallery-slide-wrapper) {
    background-color: transparent;
    border: none;
  }

  & :global(.image-gallery-thumbnails-container),
  & :global(.image-gallery-thumbnail) {
    text-align: center !important;
  }
}

.root {
}

.itemWrapper,
.itemWrapperFullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
}
.itemWrapper > div {
  padding-bottom: 66.66% !important;
  @media (--viewportLarge) {
    padding-bottom: 100% !important;
  }
}
.listingImageGallarySlider {
  position: relative;
  & .viewPhotos {
    position: absolute;
    z-index: 1;
    right: 10px;
    bottom: 9px;
    top: auto;

    & > button {
      line-height: 120%;
      font-size: 14px;
      font-weight: normal;
      padding: 6px 14px;
      background-color: var(--matterColorNegative);
      color: var(--matterColorDark);
      &:hover {
        background-color: var(--matterColorLight);
        color: var(--matterColorDark);
      }
    }
  }
  & :global(.slick-list) {
    border-radius: 8px;
    margin-bottom: 10px;
    border: solid 1px rgba(232, 232, 232, 1);
  }
  & :global(.slick-arrow > svg) {
    margin: 0;
  }
  &.thumbSlider {
    & .viewPhotos {
      bottom: 68px;
      @media (--viewportMedium) {
        bottom: 83px;
      }
      @media (--viewportLarge) {
        bottom: 95px;
      }
    }

    & .itemCentering {
    }
  }
  & :global(.slick-slide > div) {
    display: flex;
  }
}

.noImage {
  border: 1px solid var(--matterColorNegative);
  border-radius: 4px;
}

.itemWrapperFullscreen {
  height: calc(100vh - 130px);
}

.itemCentering {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.item {
  max-width: 100%;
  max-height: 100%;
  /* object-fit: contain;
  width: 570px;
  height: 670px; */
  position: relative;
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.thumb {
  /* With the active thumbnail border (default 4px), make the result
   height a multiple of the baseline. */
  max-width: 100%;
  max-height: 88px;
  border-radius: 4px;
  width: 100%;
  object-fit: contain;
}

.navLeft,
.navRight {
  position: absolute;
  width: 60px;
  height: 100%;
  border: 0;
  padding: 0;
  z-index: 1;
  outline: none;
  box-shadow: none;

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;

    & .navArrowWrapper {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.navLeft {
  left: 0;

  & .navArrowWrapper {
    padding-right: 2px;
  }
}

.navRight {
  right: 0;

  & .navArrowWrapper {
    padding-left: 2px;
  }
}

.navArrowWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
}

.openFullscreen {
  /* Hide on mobile. Currently react-image-gallery fullscreen mode is broken on
     mobile, and mobile doesn't really benefit from fullscreen anyways. */
  display: none;

  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  /* Ensure the button is on top of the navRight area */
  z-index: 1;

  &:hover {
    background-color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportLarge) {
    display: block;
  }
}

.close {
  composes: marketplaceModalCloseStyles from global;

  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--matterColorDark);
  }
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}
